import React from 'react';

const AboutPage = () => {
  return (
    <div className="page">
      <h1>About this Site</h1>
      <p>Version V0.2</p>
      <p>
        Just a personal project gathering vehicle positions from several (~45)
        mobility service providers using the Multicyles Api
      </p>
    </div>
  );
};

export default AboutPage;
